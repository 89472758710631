import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, ButtonGroup, Chip, Typography } from '@mui/material'
import withTheme from '@mui/styles/withTheme';
import withStyles from '@mui/styles/withStyles';

import { withNavigate } from 'components/hooks/HOC.js'
import Footer from 'components/layout/Footer.js'
import Header from 'components/layout/Header.js'
import ContestInfo from './ContestInfo.js'
import ContestSections from './ContestSections.js'
import ContestTimetable from './ContestTimetable.js'
import ContestLivestock from './ContestLivestock.js'
import Video from 'components/pages/contest/Video.js'
import API from 'services/Api.js'
import Loader from 'components/ui/Loader.js'
import ReactGA from "react-ga4"


const styles = theme => ({
  waiting: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  gridParent: {
    display: "flex",
    gap: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flexWrap: "nowrap",
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: "wrap",
      justifyContent: "center",
      margin: 0,
    },
  },
  gridInfo: {
    maxWidth: 320,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  gridSection: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  gridVideo: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    width: "100%",
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      gap: theme.spacing(1),
    },
  },
  btnGroupMainSections: {
    display: "block",
    textAlign: "left",
    borderRadius: 0,
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
  btnSection: {
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      padding: "5px 6px",
    },
  },
  btnSectionSelected: {
    borderRadius: 0,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      filter: "brightness(0.9)",
    },
    [theme.breakpoints.down('md')]: {
      padding: "5px 6px",
    },
  },
  table: {
    width: "auto",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      width: "100%",
    },
  },
  title: {
    textAlign: "left",
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    textAlign: "left",
    marginTop: theme.spacing(4),
  },
  cellPos: {
    padding: 0,
    maxWidth: 32,
  },
  link: {
    color: "black",
  },
});


class Contest extends React.PureComponent {
  constructor(props) {
    super(props)
    this._is_mounted = false
    this._interval_contest = null
    this.first_time = true
    let query = new URLSearchParams(this.props.location.search)
    this.resize = this.resize.bind(this)

    const main_section_name = props.params.discipline === 'morf' ? 'secciones': 'niveles';
    this.state = {
      main_section_name,
      main_section: query.get('main_section') || main_section_name,
      section: query.get('section') || '',
      currentBreakpoint: "",
      participants: [],
      participants_semifinal: [],
      pe: [],
      pe_scores: {},
      jmorf: [],
      jfunc: [],
      judges: [],
      judges_p1: [],
      judges_p2: [],
      judges_p3: [],
      amount_participants: 0,
      extra_info: [],
      contest: {
        sections: {},
        breakpoint: null,
        sicab: false,
      },
      searching: false,
      round: query.get('round') || null,
      semifinal: false,
      scoresDefinition: [],
    }
  }

  componentDidMount() {
    ReactGA.send({
      hitType: "pageview",
      page: `/contests/${this.props.params.discipline}/${this.props.params.id}`,
      title: "Contest",
    });
    this._is_mounted = true
    window.addEventListener("resize", this.resize)
    this.resize()
    this.loadContest()
    let query = new URLSearchParams(this.props.location.search)
    let main_section = query.get('main_section')
    if (main_section === this.state.main_section_name) {
      this._interval_contest = setInterval(this.loadContest.bind(this), 10000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location || this.state.section !== prevState.section) {
      let query = new URLSearchParams(this.props.location.search)
      let main_section = query.get('main_section')

      clearInterval(this._interval_contest)
      if (main_section === this.state.main_section_name) {
        this._interval_contest = setInterval(this.loadContest.bind(this), 10000)
      }

      if (main_section !== this.state.main_section) {
        this.setState({main_section: main_section})
      } else {
        if(query.get('main_section') !== 'ganaderías') {
          this.loadContest(query.get('section') || '')
        }
      }

      if (this.props.location.state && this.props.location.state.fromLivestock) {
          this.loadContest(query.get('section') || '')
      }
    }

    // Remove check interval for blocked contest
    if (this._interval_contest !== null && this.state.contest.blocked) {
      clearInterval(this._interval_contest)
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearInterval(this._interval_contest)
    window.removeEventListener("resize", this.resize)
  }

  resize() {
    let res = "xs"
    if (window.innerWidth < this.props.theme.breakpoints.values["sm"]) {
      res = "xs"
    } else if (window.innerWidth < this.props.theme.breakpoints.values["md"]) {
      res = "sm"
    } else if (window.innerWidth < this.props.theme.breakpoints.values["lg"]) {
      res = "md"
    } else if (window.innerWidth < this.props.theme.breakpoints.values["xl"]) {
      res = "lg"
    } else {
      res = "xl"
    }
    if (this.state.currentBreakpoint !== res) {
      this.setState({currentBreakpoint: res})
    }
  }

  saveHistory(section) {
    let query = new URLSearchParams({
      'main_section': this.state.main_section,
      'section': section,
    })
    this.props.navigate({ search: "?" + query.toString() })
  }

  saveMainSectionHistory(main_section) {
    let query = new URLSearchParams({'main_section': main_section})
    this.props.navigate({ search: "?" + query.toString() })
  }

  saveRoundSectionHistory(round) {
    let query = new URLSearchParams({'main_section': this.state.main_section, 'section': this.state.section, 'round': round})
    this.props.navigate({ search: "?" + query.toString() })
  }

  extractYear = (date) => {
    if (typeof date == "string") {
      return date.split('/').pop()
    } else {
      return ""
    }
  }

  loadContest(section=null) {
    if (!section) {
      section = this.state.section
    }

    if (this.first_time || this.state.section !== section) this.setState({ searching: true });

    let contest_id = this.props.params.id
    let params = {'section': section, 'discipline': this.props.params.discipline}

    API.contest.getContest(contest_id, params)
      .then(resp => {
        if (this._is_mounted) {
          this.setState({
            contest: resp.contest,
            jmorf: resp.jmorf,
            jfunc: resp.jfunc,
            judges: resp.judges || [],
            judges_p1: resp.judges_p1 || [],
            judges_p2: resp.judges_p2 || [],
            judges_p3: resp.judges_p3 || [],
            mf: resp.mf || null,
            amount_participants: resp.amount_participants,
            extra_info: resp.extra_info,
            participants: resp.participants,
            participants_semifinal: resp.participants_semifinal,
            section: section,
            pe: resp.pe || [],
            pe_scores: resp.pe_scores || {},
            searching: false,
            round: this.getRoundToDisplay(resp),
            participants_in_final: resp.participants_in_final,
            jsemi: resp.jsemi,
            scoresDefinition: resp.scores_definition,
            daysDifferent: resp.days_different,
          });
        }
      })
      .catch(error => {
        this.setState({searching: false})
        console.log("Error loading contest: " + error, contest_id);
      });
  }

  changeMainSection = (main_section) => {
    this.saveMainSectionHistory(main_section)
    this.setState({main_section: main_section, section: null, round: null})
  }

  changeRoundSection = (round) => {
    this.saveRoundSectionHistory(round)
    this.setState({ round })
  }

  changeSelectSection = (event) => {
    this.saveHistory(event.target.value)
    this.setState({ section: event.target.value, round: null });
  }

  changeButtonGroupSection = (section) => {
    this.saveHistory(section)
    this.setState({section: section, round: null})
  }

  getRoundToDisplay(resp) {
    return this.state.round
      ? this.state.round
      : resp.participants_in_final ||
        (!resp.participants_in_final &&
          resp.participants_semifinal !== undefined &&
          resp.participants_semifinal.length === 0)
        ? "finales"
        : resp.semifinal &&
          resp.participants_semifinal !== undefined &&
          resp.participants_semifinal.length > 0
          ? "semifinales"
          : "finales"
  }

  render_main_sections() {
    const { classes } = this.props
    let main_sections = [this.state.main_section_name, "horarios", "ganaderías"]

    return (
      <ButtonGroup className={classes.btnGroupMainSections} color="secondary">
          { main_sections.map((main_section, key) => {
            let cn = (main_section === this.state.main_section) ? classes.btnSectionSelected : classes.btnSection
            return (
              <Button key={key} className={cn} onClick={() => this.changeMainSection(main_section)}>
                {main_section}
              </Button>
            )
          })}
      </ButtonGroup>
    )
  }

  render() {
    const { classes } = this.props
    const discipline = this.props.params.discipline

    if (this.state.searching && this.first_time) {
      return <Loader class={classes.waiting} />
    }
    this.first_time = false

    const isMobile = ["xs", "sm"].includes(this.state.currentBreakpoint) ? true : false

    return (
      <Box>
        <Header />

        {/* TWO COLUMNS OR ONE DEPENDS ON MOBILE */}
        <div
          className={classes.gridParent}
          container
          spacing={isMobile ? 1 : 4}
        >
          {/* CONTEST INFO */}
          <ContestInfo
            className={classes.gridInfo}
            discipline={discipline}
            contest={this.state.contest}
            section={this.state.section}
            jmorf={this.state.jmorf}
            jfunc={this.state.jfunc}
            judges={this.state.judges}
            judges_p1={this.state.judges_p1}
            judges_p2={this.state.judges_p2}
            judges_p3={this.state.judges_p3}
          />

          {/* SELECTED PRIMARY TABS */}

          {/* SECTIONS */}
          <div className={classes.gridSection}>
            {this.state.contest.live_videos &&
              <div className={classes.gridVideo}>
                { this.state.contest.live_videos.map((video, key) => {
                  return (
                    <Video key={key} source={video} code={this.state.contest.code} />
                  )
                })
              }
            </div>
            }

            {!isMobile && (
              <Typography className={classes.title} variant="h2">
                {this.state.contest.name}{" "}
                {this.state.contest.extra &&
                  this.state.contest.extra.provisional && (
                    <Chip size="small" label="PROVISIONAL" />
                  )}
              </Typography>
            )}

            {this.render_main_sections()}

            {this.state.main_section === "horarios" && (
              <ContestTimetable
                isMobile={isMobile}
                discipline={discipline}
                contest_code={this.props.params.id}
              />
            )}

            {this.state.main_section === "ganaderías" && (
              <ContestLivestock
                isMobile={isMobile}
                discipline={discipline}
                contest_code={this.props.params.id}
                changeSelectSection={this.changeSelectSection}
                changeButtonGroupSection={this.changeButtonGroupSection}
                section={this.state.section}
              />
            )}

            {this.state.main_section === this.state.main_section_name && (
              <ContestSections
                key={this.state.section}
                isMobile={isMobile}
                discipline={discipline}
                contest_code={this.props.params.id}
                changeSelectSection={this.changeSelectSection}
                changeButtonGroupSection={this.changeButtonGroupSection}
                section={this.state.section}
                contest={this.state.contest}
                participants={this.state.participants}
                participants_semifinal={this.state.participants_semifinal}
                pe={this.state.pe}
                pe_scores={this.state.pe_scores}
                jmorf={this.state.jmorf}
                jfunc={this.state.jfunc}
                judges={this.state.judges}
                mf={this.state.mf}
                amount_participants={this.state.amount_participants}
                extra_info={this.state.extra_info}
                round={this.state.round}
                changeRoundSection={this.changeRoundSection}
                searching={this.state.searching}
                participants_in_final={this.state.participants_in_final}
                jsemi={this.state.jsemi}
                scoresDefinition={this.state.scoresDefinition}
                daysDifferent={this.state.daysDifferent}
              />
            )}
          </div>
        </div>

        <Footer />
      </Box>
    );
  }
}

Contest.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme(withStyles(styles)(withNavigate(Contest)))
