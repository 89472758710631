import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles';

import footer from '../../assets/footer/footer@3x.png'
import packageJson from '../../../package.json';


const styles = theme => ({
  box: {
    backgroundImage: `url(${footer})`,
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "400px",
    display: "flex",
    justifyContent: "end",
    flexDirection: "column",
    gap: "70px",
    color: "white",
  },
  textFooter: {
    textAlign: "center",
  },
  version: {
    textAlign: "left",
    padding: theme.spacing(0.5),
  },
  [theme.breakpoints.down('sm')]: {
    box: {
      gap: theme.spacing(1),
    },
    version: {
      textAlign: "center",
      paddingBottom: "75px",
      color: "lightgray",
    },
  }
});


class Footer extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      (<Box className={classes.box}>
        <Typography className={classes.textFooter} variant="body2">
          ANCCE Asociación Nacional de Criadores de Caballos de Pura Raza Española
          <br />
          © ANCCE {(new Date()).getFullYear()}
        </Typography>
        <Typography className={classes.version} variant="body2">
          v{packageJson.version}
        </Typography>
      </Box>)
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)
